import React from "react";
import styles from "./HotelPage.scss";

const HotelPageMaxWidth = (props: React.PropsWithChildren<object>) => {
    const {children} = props;
    return (
        <div className={styles.maxWidth}>
            {children}
        </div>
    );
};
HotelPageMaxWidth.displayName = "HotelPageMaxWidth";
export default HotelPageMaxWidth;
