import React from "react";
import styles from "../HotelPage.scss";
import {Gapped, Link} from "@skbkontur/react-ui";
import {RequisitesLightboxContext} from "../../../components/RequisitesLightbox/RequisitesLightboxProvider/RequisitesLightboxContext";
import AgreementLink from "../../../components/AgreementLink/AgreementLink";
import {IAppState} from "../../../store/AppState";
import {useSelector} from "react-redux";
import {LanguageSelect, useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import GlobeBlack from "../../../images/GlobeBlack.svg";

const HotelFooter = () => {
    const {openRequisitesLightbox} = React.useContext(RequisitesLightboxContext);
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const {offerAgreementLink, organizationName} = useSelector((state: IAppState) => state.hotelInfo.info);
    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.footer}>
            <div>
                <div className={styles.copyright}>
                    © {currentYear} {organizationName}
                </div>
                <div className={styles.links}>
                    <Gapped gap={0} vertical>
                        {offerAgreementLink && (
                            <Link use="grayed" href={offerAgreementLink} target="_blank">
                                {t("offer")}
                            </Link>
                        )}
                        <Link use="grayed" onClick={openRequisitesLightbox}>
                            {t("requisites.name")}
                        </Link>
                        <AgreementLink grayed />
                    </Gapped>
                </div>
            </div>
            <div className={styles.languageSelect}>
                <LanguageSelect image={GlobeBlack} withArrow />
            </div>
        </div>
    );
};
HotelFooter.displayName = "HotelFooter";
export default HotelFooter;
