import React from "react";
import {useSelector} from "react-redux";
import {Loader} from "@skbkontur/react-ui";
import styles from "./HotelPage.scss";
import AllBookingProviders from "../../providers/AllBookingProviders";
import {HotelNotFound} from "../../components/HotelNotFound/HotelNotFound";
import HotelHeader from "./Header/HotelHeader";
import HotelCategories from "./Categories/HotelCategories";
import {IAppState} from "../../store/AppState";
import HotelContent from "./Content/HotelContent";
import HotelContacts from "./Contacts/HotelContacts";
import HotelFooter from "./Footer/HotelFooter";
import CookieConsent from "../../components/CookieConsent/CookieConsent";
import {
    CategorySearchParamsProvider,
    HourlyObjectsSearchParamsProvider,
} from "../../providers/SearchParams/SearchParamsProvider";
import HourlyObjects from "./HourlyObjects/HourlyObjects";
import {useInitVkBridge} from "../../hooks/useInitVkBridge";
import {useIframeEventAnalytics} from "../../hooks/useIframeEventAnalytics";

const HotelPage = () => {
    const {organizationName} = useSelector((state: IAppState) => state.hotelInfo.info);
    const {hotelNotFound} = useSelector((state: IAppState) => state.globalError);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {hourlyObjects} = useSelector((state: IAppState) => state.hourlyObjects);

    const isLoading = useSelector((state: IAppState) => state.roomCategories.isLoading || state.hotelInfo.isLoading);

    useInitVkBridge();
    useIframeEventAnalytics();

    React.useEffect(() => {
        document.title = organizationName;
    }, [organizationName]);

    return (
        <CategorySearchParamsProvider syncUrlParams>
            <HourlyObjectsSearchParamsProvider>
                <AllBookingProviders>
                    <Loader active={isLoading} className={styles.loader} type="big">
                        <div className={styles.container}>
                            {/* TODO Заюзать тут свойства по всю высоту, как в Отеле, когда фронты объединяться */}
                            {isLoading && <div className={styles.parandja} />}
                            {hotelNotFound ? (
                                <HotelNotFound />
                            ) : (
                                <div>
                                    <HotelHeader />
                                    <div className={styles.body}>
                                        <HotelContent />
                                        {!!roomCategories?.length && <HotelCategories />}
                                        {!!hourlyObjects?.length && <HourlyObjects />}
                                        <HotelContacts />
                                        <HotelFooter />
                                    </div>
                                </div>
                            )}
                        </div>
                        <CookieConsent fixed />
                    </Loader>
                </AllBookingProviders>
            </HourlyObjectsSearchParamsProvider>
        </CategorySearchParamsProvider>
    );
};
HotelPage.displayName = "HotelPage";
export default HotelPage;
