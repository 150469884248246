import {DeviceHelper} from "../../../helpers/DeviceHelper";
import styles from "./PaymentByLinkPagePaymentSource.scss";
import {TBankSbpDesktopPayment} from "../../../components/BookingMakeOrder/TBankSbp/TBankSbpDesktopPayment";
import React from "react";
import {PaymentSystem} from "../../../data/Payment";

interface IPaymentByLinkPagePaymentSourceProps {
    url: string;
    paymentSource: PaymentSystem;
}

const PaymentByLinkPagePaymentSource = (props: IPaymentByLinkPagePaymentSourceProps) => {
    const {url, paymentSource} = props;

    const needToRedirect = paymentSource === PaymentSystem.YandexCheckout || DeviceHelper.isMobile();

    if (needToRedirect) {
        window.location.href = url;
        return null;
    }

    return (
        <div className={styles.tBankContainer}>
            <TBankSbpDesktopPayment paymentUrl={url} />
        </div>
    );
};

export default PaymentByLinkPagePaymentSource;
PaymentByLinkPagePaymentSource.displayName = "PaymentByLinkPagePaymentSource";
