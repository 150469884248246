import React from "react";
import cn from "classnames";
import styles from "./HotelItem.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";

export const EmptyHotelItem = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    return (
        <div className={cn(styles.item, styles.itemEmpty)}>
            <div className={styles.itemImage}>
                <div className={styles.itemImageSrc} />
            </div>
            <div className={styles.itemContent}>{t("roomsNotFoundOnDate")}</div>
        </div>
    );
};
