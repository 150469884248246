import React from "react";
import {useSelector} from "react-redux";
import styles from "./HotelDescription.scss";
import {AddressHelper} from "../../helpers/AddressHelper";
import {IAppState} from "../../store/AppState";
import HotelDescriptionCheckInCheckOutTime from "./HotelDescriptionCheckInCheckOutTime";
import HotelDescriptionFreeChildrenAge from "./HotelDescriptionFreeChildrenAge";
import {isNumber} from "lodash";

const HotelDescription = () => {
    const {
        freeChildrenAge,
        checkInTime,
        checkOutTime,
        address,
        description,
        organizationName
    } = useSelector((state: IAppState) => state.hotelInfo.info);

    const showCheckInOut = !!checkInTime && !!checkOutTime;
    const showFreeChildrenAge = isNumber(freeChildrenAge);

    return (
        <div>
            <h1 className={styles.title}>{organizationName}</h1>
            <div className={styles.address}>
                {AddressHelper.getUnbreakableAddress(address)}
            </div>
            <pre className={styles.description}>{description}</pre>
            {(showCheckInOut || showFreeChildrenAge) && (
                <div className={styles.bookingInfo}>
                    {showCheckInOut && <HotelDescriptionCheckInCheckOutTime />}
                    {showFreeChildrenAge && <HotelDescriptionFreeChildrenAge />}
                </div>
            )}
        </div>
    );
};
HotelDescription.displayName = "HotelDescription";
export default HotelDescription;
