import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import HotelPageMaxWidth from "../HotelPageMaxWidth";
import Title from "../../../components/Title/Title";
import RoomCategoriesList from "../../../components/RoomCategoriesList/RoomCategoriesList";

const HotelCategories = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    return (
        <HotelPageMaxWidth>
            <Title id="rooms">{t("rooms")}</Title>
            <RoomCategoriesList />
        </HotelPageMaxWidth>
    );
};

HotelCategories.displayName = "HotelCategories";
export default HotelCategories;
