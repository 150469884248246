import React from "react";
import ReactDOM from "react-dom";
import {Router} from "react-router-dom";
import App from "./components/App";
import {BrowserHelper} from "./helpers/BrowserHelper";
import {initSentry} from "@skbkontur/hotel-sentry";
import {createBrowserHistory} from "history";

module?.hot?.accept();

const history = createBrowserHistory();

initSentry({
    options: {
        dsn: "https://e4f2ddb21207410aa0af246214fa95d0@sentry.kontur.host/301"
    },
    reactRouterOptions: {history}
});

const rootNode = document.getElementById("root");
if (!BrowserHelper.isIE()) {
    ReactDOM.render((
        <Router history={history}>
            <App />
        </Router>
    ), rootNode);
}
