import styles from "./PaymentByLinkPageError.scss";
import CounterError from "../../../images/CounterError.svg";
import React from "react";

interface IPaymentByLinkPageErrorProps {
    errorText: React.ReactNode;
}

const PaymentByLinkPageError = (props: IPaymentByLinkPageErrorProps) => {
    const {errorText} = props;
    return (
        <div className={styles.error}>
            <img src={CounterError} alt="counterError"/>
            <div className={styles.errorText}>
                {errorText}
            </div>
        </div>
    );
};

export default PaymentByLinkPageError;
PaymentByLinkPageError.displayName = "PaymentByLinkPageError";
