import React from "react";
import styles from "./HotelContacts.scss";

interface IHotelContactsItemProps {
    values: string[];
    icon: React.ReactElement;
}

const HotelContactsItem = (props: IHotelContactsItemProps) => {
    const {values, icon} = props;

    if (!values?.length || !values.some(Boolean))
        return null;

    return (
        <div className={styles.contact}>
            <span className={styles.icon}>{icon}</span>
            <span className={styles.text}>{values.join(", ")}</span>
        </div>
    );
};
HotelContactsItem.displayName = "HotelContactsItem";
export default HotelContactsItem;
