import styles from "../MainPage.scss";
import MainPageResultsIcon from "../../../images/MainPageResultsIcon.svg";
import {HotelItem} from "../HotelItem/HotelItem";
import {EmptyHotelItem} from "../HotelItem/EmptyHotelItem";
import React from "react";
import {UrlParamsExtractor} from "../../../helpers/UrlParamsExtractor";
import {IPlaceSearchParams} from "../../../data/SearchParams";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import {useHistory} from "react-router";

const SearchResults = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const {hotels} = useSelector((state: IAppState) => state.hotelsSearch);
    const {location} = useHistory();

    const redirectToHotelSite = (alias: string) => {
        const {place, ...filter} = UrlParamsExtractor.getSearchParams<IPlaceSearchParams>(location);
        window.open(`/${alias}/${location.search}`, Object.values(filter).join("-"));
    };

    return (
        <div className={styles.resultsWrap}>
            <div className={styles.results}>
                <div className={styles.resultsHeader}>
                    <img src={MainPageResultsIcon} className={styles.resultsHeaderIcon} />
                    {t("MainPage.searchResults")}
                </div>
                <div>
                    {hotels?.length
                        ? hotels.map(hotel => (
                            <HotelItem
                                hotel={hotel}
                                onClick={() => redirectToHotelSite(hotel.alias)}
                                key={hotel.alias}
                            />
                        ))
                        : <EmptyHotelItem />}
                </div>
            </div>
        </div>
    );
};

SearchResults.displayName = "SearchResults";
export default SearchResults;
