import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import styles from "./HotelDescription.scss";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import {ChildBabyIcon16Solid} from "@skbkontur/icons/ChildBabyIcon16Solid";

const HotelDescriptionFreeChildrenAge = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const {freeChildrenAge} = useSelector((state: IAppState) => state.hotelInfo.info);

    return (
        <div className={styles.infoRow}>
            <ChildBabyIcon16Solid color="#4E93C8" />
            <span className={styles.infoRowText}>
                {t("HotelDescription.childrenUnderAgeFree", {count: freeChildrenAge})}
            </span>
        </div>
    );
};
HotelDescriptionFreeChildrenAge.displayName = "HotelDescriptionFreeChildrenAge";
export default HotelDescriptionFreeChildrenAge;
