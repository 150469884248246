import React from "react";
import cn from "classnames";
import styles from "./HotelHeader.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import {GalleryContext} from "../../../providers/Gallery/GalleryContext";
import {Img} from "@skbkontur/hotel-components/image";

interface IHeaderImageProps {
    index: number;
    moreCount?: number;
}

const HotelHeaderImage = (props: IHeaderImageProps) => {
    const {index, moreCount} = props;

    const {openGallery} = React.useContext(GalleryContext);
    const {images, imagesThumbnail} = useSelector((state: IAppState) => state.hotelInfo.info);

    const imageUrl = images[index]?.url;
    const imagesThumbnailUrl = imagesThumbnail[index]?.url;

    const {tcn} = useTranslation(TranslationNamespaces.Common);

    const isActive = !!imageUrl;
    const imageClassNames = cn(styles.image, {[styles.active]: isActive});

    const handleOpenGallery = () => {
        openGallery(images, index);
    };

    return (
        <div className={styles.imageWrapper}>
            {moreCount > 0 && (
                <span className={styles.nameplate}>
                    {tcn("photos", {count: moreCount})}
                </span>
            )}
            {isActive && (
                <div className={imageClassNames} onClick={handleOpenGallery}>
                    <Img src={imagesThumbnailUrl} />
                </div>
            )}
        </div>
    );
};
HotelHeaderImage.displayName = "HotelHeaderImage";
export default HotelHeaderImage;
