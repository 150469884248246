import React from "react";
import {useSelector} from "react-redux";
import styles from "./HotelHeader.scss";
import {DeviceContext, DeviceMode} from "../../../providers/Device/DeviceContext";
import {IAppState} from "../../../store/AppState";
import HotelHeaderImage from "./HotelHeaderImage";

const getSmallImagesCount = (deviceMode: DeviceMode, imagesCount: number) => {
    let maxCount;
    switch (deviceMode) {
        case DeviceMode.MobileLarge:
        case DeviceMode.Mobile:
            maxCount = 0;
            break;
        case DeviceMode.Tablet:
        case DeviceMode.DesktopMini:
            maxCount = 2;
            break;
        default:
            maxCount = 4;
    }
    return Math.max(0, Math.min(maxCount, imagesCount - 1));
};

const HotelHeader = () => {
    const {deviceMode, isMobileMode, isDesktopMode} = React.useContext(DeviceContext);

    const images = useSelector((state: IAppState) => state.hotelInfo.info.images);

    const imagesCount = images?.length || 0;
    const smallImagesCount = getSmallImagesCount(deviceMode, imagesCount);
    const moreCount = Math.min(0, imagesCount - 1 - smallImagesCount);

    return (
        <div className={styles.header}>
            <div className={styles.headerImages}>
                <div className={styles.mainImage}>
                    <HotelHeaderImage index={0} moreCount={isMobileMode && moreCount} />
                </div>
                {!isMobileMode && (
                    <div className={styles.smallImages}>
                        <div className={styles.imagesRow}>
                            <HotelHeaderImage index={1} />
                            {isDesktopMode && <HotelHeaderImage index={3} />}
                        </div>
                        <div className={styles.imagesRow}>
                            {isDesktopMode && <HotelHeaderImage index={4} />}
                            <HotelHeaderImage index={2} moreCount={moreCount} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
HotelHeader.displayName = "HotelHeader";
export default HotelHeader;
