import React from "react";

// TODO копия с отеля, объединить после единого фронта
export function useConstructor(constructor: () => void) {
    const firstRender = React.useRef(true);
    if (firstRender.current === true) {
        firstRender.current = false;
        constructor();
    }
}
